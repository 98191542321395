import { RazorpayService } from './../../common/common-service/razorpay.service';
import { environment } from './../../../environments/environment';
import { rejected } from './../../rd-eform/admin/application-status';
import { NNNagiDTO } from './../model/nnnagi.dto';
import { NnnagiPdfService } from './../../common/common-service/nnnagi-pdf.service';
import { UserService } from 'app/rd-eform/services/user.service';
import { FirstSection } from './../model/first-section.model';
import { AfterViewInit, Component, NgZone, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { OtpServiceService } from 'app/common/common-service/otp-service.service';
import { WindowRefService } from 'app/common/common-service/window-ref.service';
import { ImageCompressService } from 'ng2-image-compress';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { PreviewComponent } from 'app/rd-eform/form/preview/preview.component';
import { OtpModel } from 'app/common/shared/models/otp-model';
import { DatePipe } from '@angular/common';
import { TrackSession } from 'app/rd-eform/dto/track-session';
import { ApplicationService } from 'app/rd-eform/services/application.service';
import { PaymentVO } from 'app/common/model/PaymentVO';
import { ExternalLibraryService } from 'app/common/common-service/util';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-sbi-collect-application',
  templateUrl: './sbi-collect-application.component.html',
  styleUrls: ['./sbi-collect-application.component.css'],
})
export class SbiCollectApplicationComponent implements OnInit, AfterViewInit {
  phonePattern = '^[0-9]{10}$';
  numberPattern = '^[0-9]*$';
  pinCodePattern = '^[0-9]{6}$';
  aadhaarPattern = '^[0-9]{12}$';
  pdf: any;

  emailPattern = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$';
  year = new Date();
  documentSection;
  firstSection;
  secondSection;
  thirdSection;
  applicationTitle;
  applicationType;
  allowOptionalPrograms = true;
  constructor(
    private router: Router,
    private _formBuilder: FormBuilder,
    private toastr: ToastrService,
    private winRef: WindowRefService,
    private otpService: OtpServiceService,
    private userService: UserService,
    private pdfService: NnnagiPdfService,
    public dialog: MatDialog,
    private datePipe: DatePipe,
    private datePipe1: DatePipe,
    private applicationService: ApplicationService,
    private razorpayService: RazorpayService,
    private externalService: ExternalLibraryService,
    private cd: ChangeDetectorRef,
    private zone: NgZone
  ) {
    this.externalService
      .lazyLoadLibrary('https://checkout.razorpay.com/v1/checkout.js')
      .subscribe();
    /* this.applicationTitle = this.userService.applicationType.applicationtype; */
    this.applicationTitle='ADMISSION 2021';
  }
  ngAfterViewInit(): void {
    this.firstSec.standard.valueChanges.subscribe(res => {
      if (res === 'NURSERY' || res === 'PRESCHOOL') {
        this.allowOptionalPrograms = false;
        this.secondSection.get('optionalPrograms').setValidators([]); // or clearValidators()
        this.secondSection.get('optionalPrograms').updateValueAndValidity();

      } else {
        this.allowOptionalPrograms = true;
        this.secondSection.get('optionalPrograms').setValidators([Validators.required]); // or clearValidators()
        this.secondSection.get('optionalPrograms').updateValueAndValidity();
      }
    })
  }

  ngOnInit(): void {
    /*     if (this.applicationTitle.includes(environment.applicationtype1)) {
          this.applicationType = true;
          console.log(this.applicationType);
        } else {
          this.applicationType = false;
        } */
    this.firstSection = this._formBuilder.group({
     /*  name: ['ABC', Validators.required], */
        name: [this.userService.firstTimeModel.fullName, Validators.required],

      presentAddress: ['', Validators.required],
      state: ['', Validators.required],
      city: ['', Validators.required],
      pinCode: ['', Validators.pattern(this.pinCodePattern)],
        dateOfBirth: [this.userService.firstTimeModel.dateOfBirth],
     /*  dateOfBirth: [''], */
      gender: ['', Validators.required],
      birthPlace: ['', Validators.required],
      schoolLastAttended: [''],
      standard: ['', Validators.required],
      schoolAddress: [''],
      lastGradeCompleted: [''],
      aadhaarNo: [''],
      religion: [],
      category: []
    });
    this.secondSection = this._formBuilder.group({
      fathersName: ['', Validators.required],
      fathersOccupation: ['', Validators.required],
      mothersName: ['', Validators.required],
      mothersOccupation: ['', Validators.required],
      homePhone: [''],
      mobile: [
        this.userService.firstTimeModel.phoneNumber,
        Validators.compose([
          Validators.required,
          Validators.pattern(this.phonePattern),
        ]),
      ],

      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(this.emailPattern),
        ]),
      ],
      optionalPrograms: [''],
      permanentAddress: [''],
      permanentState: [''],
      permanentCity: [''],
      permanentPinCode: ['', Validators.pattern(this.pinCodePattern)],
    });
    this.thirdSection = this._formBuilder.group({
      emergencyContactName: ['', [Validators.required]],
      emergencyContactNumber: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(this.phonePattern),
        ]),
      ],
      bloodGroup: ['', Validators.required],
      familyPhysiciansPhone: ['', Validators.pattern(this.phonePattern)],
      familyPhysiciansEmail: ['', Validators.pattern(this.emailPattern)],
      familyPhysiciansAddress: [''],

      allergyExplain: [''],
      immunizationPolio: [false],
      immunizationMMR: [false],
      immunizationChickenPox: [false],
      immunizationChickenHB: [false],
    });
    this.documentSection = this._formBuilder.group({
      photo: ['', Validators.required],
      mothersSignature: ['', Validators.required],
      fathersSignature: ['', Validators.required],
      file1: [''],
    });
  }

  payWithSbiCollect() {
    if (this.documentSection.invalid) {
      this.toastr.warning('Please complete application');
      return;
    }
    const applicationDTO = new NNNagiDTO();
    applicationDTO.status = 'pending';
    applicationDTO.transactionId = this.userService.firstTimeModel.transactionId;
    applicationDTO.applicationtypeid = this.userService.applicationType.id;
    applicationDTO.applicationtypename = 'ADMISSION 2021';
    applicationDTO.createdOn = this.datePipe.transform(
      new Date(),
      'yyyy-MM-dd'
    );
    applicationDTO.firstSection = this.firstSection.getRawValue();
    applicationDTO.firstSection.dateOfBirth = this.datePipe.transform(
      new Date(applicationDTO.firstSection.dateOfBirth),
      'yyyy-MM-dd'
    );
    applicationDTO.secondSection = this.secondSection.getRawValue();
    applicationDTO.secondSection.optionalPrograms = this.getStringFromArray(
      applicationDTO.secondSection.optionalPrograms
    );
    applicationDTO.secondSection.email = applicationDTO.secondSection.email.toLowerCase();
    applicationDTO.thirdSection = this.thirdSection.getRawValue();
    applicationDTO.thirdSection.familyPhysiciansEmail = applicationDTO.thirdSection.familyPhysiciansEmail.toLowerCase();
    applicationDTO.documentSection = this.documentSection.getRawValue();
    const pdf = this.pdfService.generatePreviewPdf(applicationDTO);
    pdf.getDataUrl((url) => {
      let otpModel = new OtpModel();
      otpModel = this.userService.otpModel;
      applicationDTO.documentSection.pdf = url;
      this.applicationService
        .submitApplication(applicationDTO, otpModel.mobileNo, otpModel.otp)
        .then(
          (res) => {
            this.toastr.success('Your reference id is:' + res, 'Success');
            this.userService.clearOtpSession();
            this.userService.clearTrackSession();
            this.userService.clearApplicationType();
            window.open(
              'https://www.onlinesbi.com/sbicollect/icollecthome.htm',
              '_self'
            );
            this.router.navigate(['/']);
          },
          (error) => {
            if (error.status === 400 || error.status === 401) {
              this.toastr.error(
                'Invalid Session. You will be redirected to login page!'
              );
              this.router.navigate(['/apply']);
            } else {
              this.toastr.warning(
                'Form not saved. Try submitting again or start fresh!'
              );
              return;
              // this.router.navigate(["/"]);
            }
          }
        );
    });
  }

  // Image upload sections
  onSelectFile(event, name: string) {
    if (event.target.files) {
      ImageCompressService.filesToCompressedImageSource(
        event.target.files
      ).then((observableImages) => {
        observableImages.subscribe(
          (image) => {
            const base64 = image.compressedImage.imageDataUrl;
            // tslint:disable-next-line: variable-name
            const base64_slice = base64.split(';base64,')[1];
            this.documentSection.get(name).setValue(base64_slice);
            this.documentSection.updateValueAndValidity();
          },
          (error) => {
            console.log('Error while converting');
          },
          () => { }
        );
      });
    }
  }
  getControlValue(name) {
    if (this.documentSection.get(name).value) {
      return 'data:image/jpeg;base64,' + this.documentSection.get(name).value;
    } else {
      return '';
    }
  }
  removeImage(name) {
    this.documentSection.controls[name].setValue('');
    this.documentSection.updateValueAndValidity();
  }
  getStringFromArray(arr) {
    if (Array.isArray(arr) && arr.length) return arr.join();
    else {
      return '';
    }
  }
  get f() {
    return this.documentSection.controls;
  }
  get firstSec() {
    return this.firstSection.controls;
  }
  razorPaySubmit(): any {
    if (this.documentSection.invalid) {
      this.toastr.warning('Please complete application');
      return;
    }
    const vo = new PaymentVO();
    vo.amount = '600';
    vo.transactionId = this.userService.firstTimeModel.transactionId;
    this.createRazpayOrder(vo);
  }
  createRazpayOrder(vo: PaymentVO) {
    this.razorpayService
      .getOrderId(
        vo,
        this.userService.otpModel.mobileNo,
        this.userService.otpModel.otp
      )
      .then(
        (res) => {

          this.payWithRazor(res);
        },
        (err) => {
          this.toastr.error('Error With Payment gateway');
        }
      );
  }

  payWithRazor(obj) {
    const options: any = {
      key: obj.secretKey,
      amount: obj.amount, // amount should be in paise format to display Rs 1255 without decimal point
      currency: 'INR',
      name: 'NN NAGI SCHOOL', // company name or product name
      description: 'Application Id ' + this.userService.firstTimeModel.transactionId, // product description
      image: '', // company logo or product image ./assets/image/logo.png
      order_id: obj.razorpayOrderId, // order_id created by you in backend
      modal: {
        // We should prevent closing of the form when esc key is pressed.
        escape: false,
        ondismiss: () => {
          this.zone.run(() => {
            this.toastr.warning('Payment cancelled by user');
          });
        },
      },
      notes: {
        // include notes if any
      },
      theme: {
        color: '#0c238a',
      },
      handler: this.paymentHandler.bind(this),
    };

    const rzp = new this.winRef.nativeWindow.Razorpay(options);
    rzp.open();
  }

  payWithOnline(obj) {
    const applicationDTO = new NNNagiDTO();
    applicationDTO.razorpay_order_id = obj.razorpay_order_id;
    applicationDTO.razorpay_payment_id = obj.razorpay_payment_id;
    applicationDTO.razorpay_signature = obj.razorpay_signature;
    applicationDTO.status = 'pending';
    applicationDTO.transactionId = this.userService.firstTimeModel.transactionId;
   /*  applicationDTO.applicationtypeid = this.userService.applicationType.id; */
/*     applicationDTO.applicationtypename = this.userService.applicationType.applicationtype; */
applicationDTO.applicationtypename='ADMISSION 2021';
    applicationDTO.createdOn = this.datePipe.transform(
      new Date(),
      'yyyy-MM-dd'
    );
    applicationDTO.firstSection = this.firstSection.getRawValue();
    applicationDTO.firstSection.dateOfBirth = this.datePipe.transform(
      new Date(applicationDTO.firstSection.dateOfBirth),
      'yyyy-MM-dd'
    );
    applicationDTO.secondSection = this.secondSection.getRawValue();
    applicationDTO.secondSection.optionalPrograms = this.getStringFromArray(
      applicationDTO.secondSection.optionalPrograms
    );
    applicationDTO.secondSection.email = applicationDTO.secondSection.email.toLowerCase();
    applicationDTO.thirdSection = this.thirdSection.getRawValue();
    applicationDTO.thirdSection.familyPhysiciansEmail = applicationDTO.thirdSection.familyPhysiciansEmail.toLowerCase();
    applicationDTO.documentSection = this.documentSection.getRawValue();
    const pdf = this.pdfService.generatePreviewPdf(applicationDTO);
    pdf.getDataUrl((url) => {
      let otpModel = new OtpModel();
      otpModel = this.userService.otpModel;
      applicationDTO.documentSection.pdf = url;
      this.applicationService
        .submitApplication(applicationDTO, otpModel.mobileNo, otpModel.otp)
        .then(
          (res) => {
            this.toastr.success('Your reference id is:' + res, 'Success');
            this.userService.clearOtpSession();
            this.userService.clearTrackSession();
            this.userService.clearApplicationType();
            this.router.navigate(['/']);
          },
          (error) => {
            if (error.status === 400 || error.status === 401) {
              this.toastr.error(
                'Invalid Session. You will be redirected to login page!'
              );
              this.router.navigate(['/apply']);
            } else {
              this.toastr.warning(
                'Form not saved. Try submitting again or start fresh!'
              );
              return;
              // this.router.navigate(["/"]);
            }
          }
        );
    });
  }
  previewApplication(): any {
    if (this.documentSection.invalid) {
      this.toastr.warning('Please complete application');
      return;
    }
    const applicationDTO = new NNNagiDTO();
    applicationDTO.status = 'pending';
    applicationDTO.transactionId = this.userService.firstTimeModel.transactionId;
   /*  applicationDTO.applicationtypeid = this.userService.applicationType.id; */
  /*   applicationDTO.applicationtypename = this.userService.applicationType.applicationtype; */
  applicationDTO.applicationtypename='ADMISSION 2021';
    applicationDTO.createdOn = this.datePipe.transform(
      new Date(),
      'yyyy-MM-dd'
    );
    applicationDTO.firstSection = this.firstSection.getRawValue();
    applicationDTO.firstSection.dateOfBirth = this.datePipe.transform(
      new Date(applicationDTO.firstSection.dateOfBirth),
      'yyyy-MM-dd'
    );
    applicationDTO.secondSection = this.secondSection.getRawValue();
    applicationDTO.secondSection.optionalPrograms = this.getStringFromArray(
      applicationDTO.secondSection.optionalPrograms
    );
    applicationDTO.secondSection.email = applicationDTO.secondSection.email.toLowerCase();
    applicationDTO.thirdSection = this.thirdSection.getRawValue();
    applicationDTO.thirdSection.familyPhysiciansEmail = applicationDTO.thirdSection.familyPhysiciansEmail.toLowerCase();
    applicationDTO.documentSection = this.documentSection.getRawValue();
    /* applicationDTO.documentSection.mothersSignature=this.documentSection.get('mothersSignature').value;
    applicationDTO.documentSection.photo=this.documentSection.get('photo').value;
    debugger;
    applicationDTO.documentSection.fathersSignature=this.documentSection.get('fathersSignature').value; */

    const pdf = this.pdfService.generatePreviewPdf(applicationDTO);
    pdf.getDataUrl((url) => {
      const dialogRef = this.dialog.open(PreviewComponent, {
        data: { dataUrl: url },
      });
      dialogRef.afterClosed().subscribe((result) => { });
    });
  }
  paymentHandler(response: any) {
    this.zone.run(() => {
      this.razorpayService.checkPaymentSignature(response).then(
        (res) => {
          if (res.statusCode === 200) {
            this.payWithOnline(response);
          } else {
            this.toastr.error('Razorpay Gateway error. Please contact Admin');
            this.router.navigate(['/']);
          }
        },
        (er) => {
          this.toastr.error('Payment gateway error.Please contact Admin');
          this.router.navigate(['/']);
        }
      );
    });
  }
}
