import { AdminService } from './rd-eform/services/admin.service';
import { RdUserGuard } from './rd-eform/guards/rd-user.guard';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppComponent } from './app.component';
import { ForbiddenComponent } from './common/forbidden';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularMaterialModule } from './angular-material/angular-material.module';
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatIconRegistry } from '@angular/material/icon';
import { DatePipe } from '@angular/common';
import { ApiInterceptor } from './rd-eform/interceptors/api-http.interceptor';
import { FlexLayoutModule } from '@angular/flex-layout';import { DocumentModalComponent } from './common/component/document-modal/document-modal.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ImageCompressService, ResizeOptions, ImageUtilityService } from 'ng2-image-compress';
import { ApplicationService } from './rd-eform/services/application.service';
import { RdAdminGuard } from './rd-eform/guards/rd-admin.guard';
import { InstructionsComponent } from './rd-eform/auth/instructions/instructions.component';
import { LoginComponent } from './student/login/login.component';
import { ApplicationComponent } from './student/application/application.component';
import { WindowRefService } from './common/common-service/window-ref.service';
import { LandingComponent } from './student/landing/landing.component';
import { RazorPayComponent } from './student/razor-pay/razor-pay.component';
import { SbiCollectApplicationComponent } from './student/sbi-collect-application/sbi-collect-application.component';
import { PreviewComponent } from './rd-eform/form/preview/preview.component';
import { StudentDashboardComponent } from './student/student-dashboard/student-dashboard.component';
import { NavbarComponent } from './common/component/navbar/navbar.component';
import { SidebarComponent } from './common/component/sidebar/sidebar.component';
import { LayoutComponent } from './common/component/layout/layout.component';
@NgModule({
  declarations: [
    AppComponent,
    ForbiddenComponent,
    DocumentModalComponent,
    InstructionsComponent,
    LoginComponent,
    ApplicationComponent,
    LandingComponent,
    RazorPayComponent,
    SbiCollectApplicationComponent,
    PreviewComponent,
    StudentDashboardComponent,
     SidebarComponent
     ,NavbarComponent, LayoutComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    AngularMaterialModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
    }),
    NgxSpinnerModule,
    NgbModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    WindowRefService,
    RdUserGuard,
    RdAdminGuard,
    ApplicationService,
    AdminService,
    MatIconRegistry,
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,
    }, ImageCompressService, ResizeOptions, ImageUtilityService
  ],

  bootstrap: [AppComponent],
})
export class AppModule {
}
