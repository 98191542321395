import { ApplicationTypeVO } from './../shared/models/applicationtype-vo';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApplicationtypeService {

  constructor(private _http: HttpClient) { }
  baseUrl = environment.apiBaseUrl

  getApplicationTypes() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
       
      })
    };
   return this._http.post<any>(this.baseUrl + '/applicationtype/get-active', '', httpOptions) as Observable<any>;
 // return of("valid");
  }
  getAllApplicationTypes() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
       
      })
    };
   return this._http.post<any>(this.baseUrl + '/applicationtype/get-all', '', httpOptions) as Observable<any>;
 // return of("valid");
  }
  updateApplicationType(object:ApplicationTypeVO){

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
       
      })
    };
   return this._http.post<any>(this.baseUrl + '/applicationtype/update', object, httpOptions).toPromise();
  }
  saveApplicationType(object:ApplicationTypeVO){

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
       
      })
    };
   return this._http.post<any>(this.baseUrl + '/applicationtype/save', object, httpOptions).toPromise();
  }
}
