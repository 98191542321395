import { PaymentVO } from './../model/PaymentVO';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RazorpayService {
  constructor(private _http: HttpClient) {}
  baseUrl = environment.apiBaseUrl;
  getOrderId(vo: PaymentVO, mobileno: any, otp: any): any {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: '*/*',
        mobileno,
        otp,
      }),
    };
    return this._http
      .post<any>(
        this.baseUrl + '/nnnagi/form/application/razorpayorderid',
        vo,
        httpOptions
      )
      .toPromise();
  }
  checkPaymentSignature(signature: any): any {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: '*/*',
      }),
    };
    return this._http
      .post<any>(
        this.baseUrl + '/nnnagi/form/application/paymentsignature',
        signature,
        httpOptions
      )
      .toPromise();
  }
}
