import { FirstTime } from './../model/first-time.model';
import { trigger, transition, useAnimation } from '@angular/animations';
import { Template } from '@angular/compiler/src/render3/r3_ast';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, NavigationEnd } from '@angular/router';
import { OtpServiceService } from 'app/common/common-service/otp-service.service';
import { OtpModel } from 'app/common/shared/models/otp-model';
import { UserService } from 'app/rd-eform/services/user.service';
import { rubberBand, slideInUp } from 'ng-animate';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-razor-pay',
  templateUrl: './razor-pay.component.html',
  styleUrls: ['./razor-pay.component.css'],
  animations: [
    trigger('bounce', [transition('* => *', useAnimation(rubberBand))]),
    trigger('slideInUp',[transition('*=>*',useAnimation(slideInUp))]),
  ],
})
export class RazorPayComponent implements OnInit {
  bounce:any;
  slideInUp:any;
  test : Date = new Date();
  firstFormGroup: FormGroup;
  otpValidated = false;
  inValidOtp = false;
  constructor(
    private _formBuilder: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private userService: UserService,
    private otpService: OtpServiceService,
    public dialog: MatDialog,
  ) {
    // tslint:disable-next-line: only-arrow-functions
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.router.navigated = false;
      }
    });
  }

  ngOnInit(): void {




    // localStorage.clear();
    this.otpValidated = false;
    this.firstFormGroup = this._formBuilder.group({
      phoneNumber: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('^[0-9]{10}$'),
        ]),
      ],
      otp: [
        '',
        Validators.compose([
          Validators.required
        ]),
      ],
    });
  }

  generateOtp() {
    this.inValidOtp = false;
    if (this.firstFormGroup.controls.phoneNumber.invalid) {
      this.toastr.warning('Please enter a valid mobile number.');
      return;
    }
    const mobileNumber = this.firstFormGroup.get('phoneNumber').value;
    const otpModel = new OtpModel();
    otpModel.mobileNo = mobileNumber;
    otpModel.source = 'rd';
    // console.log(otpModel);
    this.otpService.getOtp('rd', mobileNumber, 'user', otpModel).subscribe(
      (res) => {
        this.otpValidated = true;
      },
      (error) => {
        if (error.status === 401) {
          this.toastr.error('Invalid details submitted.Please try again.');
        } else if (error.status === 409) {
          // mobile number already exists
          this.toastr.error(
            'Mobile number already applied for another application.'
          );
        } else {
          this.toastr.error('OTP generation failed! Please try again later.');
        }
      }
    );
  }

  validateOtp() {
    const mobileNumber = this.firstFormGroup.get('phoneNumber').value;

    const otp = this.f.otp.value;

    this.otpService.validateOtp('rd', mobileNumber, 'user', otp,new FirstTime()).subscribe(
      (res) => {
        console.log(res.token);
        if (res.token === 'valid') {
          const otpModel = new OtpModel();
          otpModel.mobileNo = mobileNumber;
          otpModel.otp = otp;
          this.userService.otpModel = otpModel;
          this.router.navigate(['/application']);
        } else {
          this.toastr.error('Invalid Otp');
        }
      },
      (error) => {
        if (error.status ===401) {
          this.toastr.error('Invalid details submitted.Please try again.');
        } else if (error.status === 409) {
          // mobile number already exists
          this.toastr.error(
            'Mobile number already applied for another application.'
          );
        } else {
          this.toastr.error('OTP generation failed! Please try again later.');
        }
      }
    );
  }
  get f() {
    return this.firstFormGroup.controls;
  }

}
