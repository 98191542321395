import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';

import { OtpServiceService } from 'app/common/common-service/otp-service.service';
import { WindowRefService } from 'app/common/common-service/window-ref.service';
import { ImageCompressService } from 'ng2-image-compress';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-application',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.css'],
  providers: [WindowRefService],
})
export class ApplicationComponent implements OnInit, AfterViewInit {
  year = new Date();

  documentUploads;
  constructor(
    private router: Router,
    private _formBuilder: FormBuilder,
    private toastr: ToastrService,
    private winRef: WindowRefService,
    private otpService: OtpServiceService
  ) {}
  ngAfterViewInit(): void {}

  ngOnInit(): void {
    this.documentUploads = this._formBuilder.group({
      passportPhoto: [''],
      mothersSignature: [''],
      fathersSignature: [''],
    });
  }
  onSubmit() {
    // this.payWithRazor("order_Fjw5U5gnXzEMlj");
    this.toastr.success('Application successfully submitted');
    this.router.navigate(['/']);
  }

  // Image upload sections
  onSelectFile(event, name: string) {
    if (event.target.files) {
      ImageCompressService.filesToCompressedImageSource(
        event.target.files
      ).then((observableImages) => {
        observableImages.subscribe(
          (image) => {
            const base64 = image.compressedImage.imageDataUrl;
            // tslint:disable-next-line: variable-name
            const base64_slice = base64.split(';base64,')[1];
            this.documentUploads.get(name).setValue(base64_slice);
            this.documentUploads.updateValueAndValidity();
          },
          (error) => {
            console.log('Error while converting');
          },
          () => {}
        );
      });
    }
  }
  getControlValue(name) {
    if (this.documentUploads.get(name).value) {
      return 'data:image/jpeg;base64,' + this.documentUploads.get(name).value;
    } else {
      return '';
    }
  }
  removeImage(name) {
    this.documentUploads.controls[name].setValue('');
    this.documentUploads.updateValueAndValidity();
  }

  onNext(stepper: MatStepper) {
    stepper.next();
    console.log('Tests');
  }

  onPrev(stepper: MatStepper) {
    stepper.previous();
    // Do something
  }
}
