import { ApplicationTypeVO } from './../../common/shared/models/applicationtype-vo';
import { Injectable } from '@angular/core';
import { OtpModel } from 'app/common/shared/models/otp-model';
import { FirstTime } from 'app/student/model/first-time.model';
import { TrackSession } from '../dto/track-session';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  otpModel: OtpModel;
  firstTimeModel:FirstTime;
  trackSession: TrackSession;
  applicationType:ApplicationTypeVO;

  constructor() { 

  }

  clearOtpSession(){
    this.otpModel = null;
  }

  clearTrackSession(){
    this.trackSession = null;
  }
  clearApplicationType(){
    this.applicationType=null;
  }
}
