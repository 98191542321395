import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';


const STORAGE_KEY = 'applications';
@Injectable({
  providedIn: 'root',
})
export class ApplicationService {
  applications = [];
  baseUrl = environment.apiBaseUrl;
  constructor(
    private _http: HttpClient

  ) {}
  submitApplication(object: any, mobileno: string, otp: string) {
      const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: '*/*',
        mobileno: mobileno,
        otp: otp,
      }),
    };

    return this._http.post<any>(this.baseUrl + '/nnnagi/form/application/submit', object,httpOptions).toPromise();
  }

  getApplication(mobileNumber: string, transcationId: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: '*/*',
        mobileno: mobileNumber,
        transactionId: transcationId
      })
    };
    return this._http.post<any>(this.baseUrl + '/nnnagi/form/application/view','',httpOptions ).toPromise();
  }
  getApplicationByStatus(mobileNumber:string,status:string){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: '*/*',
        mobileno: mobileNumber,
      })
    };
    return this._http.get<any>(this.baseUrl + '/users' ).toPromise();
  }








}
