import { AdminService } from './rd-eform/services/admin.service';
import { trigger, transition, useAnimation } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { bounce } from 'ng-animate';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],

})

export class AppComponent implements OnInit{
  loggedInAdmin;
  constructor(public adminService:AdminService){


  }
  ngOnInit(): void {
    this.loggedInAdmin=this.adminService.getToken();
  }
}
