import { ToastrModule, ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { flip, rotateIn } from "ng-animate";

import { Component, OnInit } from "@angular/core";
import { AdminService } from "app/rd-eform/services/admin.service";
import * as $ from "jquery";
import { trigger, transition, useAnimation } from "@angular/animations";
/* declare const $: any; */
declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}
export const ROUTES: RouteInfo[] = [
  {
    path: "/admin/dashboard",
    title: "Dashboard",
    icon: "dashboard",
    class: "",
  },
  {
    path: "/admin/downloads",
    title: "Downloads",
    icon: "attachment",
    class: "",
  },
 /*  {
    path: "/admin/types",
    title: "Manage Types",
    icon: "content_paste",
    class: "",
  }, */
/*   { path: "/admin/notify", title: "Notify Applicant", icon: "textsms", class: "" }, */
  {path:"/admin/search",title:"Search Application",icon:"person_search",class:""}
];

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
  animations: [trigger("flip", [transition("* => *", useAnimation(rotateIn))])],
})
export class SidebarComponent implements OnInit {
  menuItems: any[];
  flip:any;
  constructor(public adminAuthService: AdminService,public router:Router,public toastr:ToastrService) {}

  ngOnInit() {
    this.menuItems = ROUTES.filter((menuItem) => menuItem);
  }
  isMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  }
  logout() {
    this.adminAuthService.removeCurrentUserValue();
    this.adminAuthService.removeToken();
    this.adminAuthService.removeMobileNumber();
    this.toastr.success("Successfully logged out");
    this.router.navigate(["/admin"]);
  }
}
