import { StudentDashboardComponent } from './student/student-dashboard/student-dashboard.component';
import { SbiCollectApplicationComponent } from './student/sbi-collect-application/sbi-collect-application.component';
import { RazorPayComponent } from './student/razor-pay/razor-pay.component';
import { LandingComponent } from './student/landing/landing.component';

import { LoginComponent } from './student/login/login.component';
import { Routes, RouterModule } from '@angular/router';
import { ForbiddenComponent } from './common/forbidden';
import { NgModule } from '@angular/core';
import { ApplicationComponent } from './student/application/application.component';
import { RdUserGuard } from './rd-eform/guards/rd-user.guard';

export const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
  },
  {
    path: 'apply',
    component: LoginComponent,
  },

  {
    path: 'sbi-collect-app',
    component: SbiCollectApplicationComponent,
  /*   canActivate: [RdUserGuard], */
  },

  {
    path: 'admin',
    loadChildren: () =>
      import(`./nnnagi-eform/nnnagi-eform.module`).then(
        (m) => m.NnnagiEformModule
      ),
  },
  {
    path: '**',
    component: ForbiddenComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
