
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { OtpModel } from '../shared/models/otp-model';
import { FirstTime } from 'app/student/model/first-time.model';

@Injectable({
  providedIn: 'root'
})
export class OtpServiceService {
  mobileNo: string;
  registrationNumber: string;
  rollNumber: string;
  applicationType: string;
  constructor(private _http: HttpClient) { }
  baseUrl = environment.apiBaseUrl


  getOtp(source: string, mobileNumber: any, role: string, otpModel: OtpModel) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: '*/*',
        source,
        mobileno: mobileNumber,
        role
      })
    };
  return this._http.post<any>(this.baseUrl + '/otp/generate', otpModel, httpOptions) as Observable<any>;
 // return of("valid");
  }
  validateOtp(source: string, mobileNumber: any, role: string, otp: any,temp: FirstTime) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: '*/*',
        // tslint:disable-next-line: object-literal-shorthand
        source: source,
        mobileno: mobileNumber,
        // tslint:disable-next-line: object-literal-shorthand
        role: role,
        // tslint:disable-next-line: object-literal-shorthand
        otp: otp,
      })
    };
    return this._http.post<any>(this.baseUrl + '/otp/validate', temp, httpOptions) as Observable<any>;
    // return of({'token':'valid','id':'123'});
  }

  getOrderId(amount: any){
    const httpOptions = {  headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: '*/*',
      Authorization:'Basic cnpwX3Rlc3RfVVVONVdBeDVqZTBDajQ6NE1EdjFvTUFCTWczU0xDczE4eHBkaW0x'
    })
  };
  return this._http.post<any>( 'https://api.razorpay.com/v1/orders', {  amount: 10000,  currency: 'INR',  receipt: 'receipt#1'}, httpOptions) as Observable<any>;

  }


}
