import { ApplicationTypeVO } from "./../../common/shared/models/applicationtype-vo";
import { FirstTime } from "./../model/first-time.model";
import { OtpServiceService } from "./../../common/common-service/otp-service.service";
import { Component, LOCALE_ID, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router, NavigationEnd } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { UserService } from "app/rd-eform/services/user.service";
import { OtpModel } from "app/common/shared/models/otp-model";
import { trigger, transition, useAnimation } from "@angular/animations";
import { bounce, flip, rubberBand, slideInUp } from "ng-animate";
import { DatePipe, formatDate } from "@angular/common";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  NativeDateAdapter,
} from "@angular/material/core";
import { ApplicationtypeService } from "app/common/common-service/applicationtype.service";
export const PICK_FORMATS = {
  parse: { dateInput: { month: "short", year: "numeric", day: "numeric" } },
  display: {
    dateInput: "input",
    monthYearLabel: { year: "numeric", month: "short" },
    dateA11yLabel: { year: "numeric", month: "long", day: "numeric" },
    monthYearA11yLabel: { year: "numeric", month: "long" },
  },
};

class PickDateAdapter extends NativeDateAdapter {
  format(date: Date, displayFormat: Object): string {
    if (displayFormat === "input") {
      return formatDate(date, "yyyy-MM-dd", this.locale);
    } else {
      //alert("hello")
      return date.toDateString();
    }
  }
}
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
  animations: [
    trigger("bounce", [transition("* => *", useAnimation(rubberBand))]),
    trigger("slideInUp", [transition("*=>*", useAnimation(slideInUp))]),
  ],
  providers: [
    { provide: DateAdapter, useClass: PickDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: PICK_FORMATS },
    { provide: LOCALE_ID, useValue: "en-US" },
  ],
})
export class LoginComponent implements OnInit {
  resendOtpCount = 2;
  bounce: any;
  slideInUp: any;
  test: Date = new Date();
  firstFormGroup: FormGroup;
  otpValidated: boolean = false;
  inValidOtp: boolean = false;
  appTypesVO: ApplicationTypeVO[];
  constructor(
    private _formBuilder: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private userService: UserService,
    private otpService: OtpServiceService,
    public dialog: MatDialog,
    private datePipe: DatePipe,
    private applicationTypeService: ApplicationtypeService
  ) {
    /* this.applicationTypeService.getApplicationTypes().subscribe(
      (res) => {
        this.appTypesVO = res;
        console.log(res);
      },
      (error) => {
        console.log("What");
      }
    ); */
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.router.navigated = false;
      }
    });
  }

  ngOnInit(): void {
    //localStorage.clear();
    this.otpValidated = false;
    this.firstFormGroup = this._formBuilder.group({
      phoneNumber: [
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern("^[0-9]{10}$"),
        ]),
      ],
      fullName: ["", Validators.required],
      otp: [
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern("^[0-9]{6}$"),
        ]),
      ],
      dateOfBirth: ["", Validators.required],
      /* applicationtype: ["", Validators.required], */
    });
  }

  generateOtp() {
    this.inValidOtp = false;
    if (this.firstFormGroup.controls["phoneNumber"].invalid) {
      this.toastr.warning("Please enter a valid mobile number.");
      return;
    }
    let mobileNumber = this.firstFormGroup.get("phoneNumber").value;
    let otpModel = new OtpModel();
    otpModel.mobileNo = mobileNumber;
    otpModel.source = "nnnagi";
    //console.log(otpModel);
    this.otpService.getOtp("nnnagi", mobileNumber, "user", otpModel).subscribe(
      (res) => {
        this.otpValidated = true;
      },
      (error) => {
        if (error.status == 401) {
          this.toastr.error("Invalid details submitted.Please try again.");
        } else if (error.status == 409) {
          // mobile number already exists
          this.toastr.error(
            "Mobile number already applied for another application."
          );
        } else {
          this.toastr.error("OTP generation failed! Please try again later.");
        }
      }
    );
  }

  validateOtp() {
    let mobileNumber = this.firstFormGroup.get("phoneNumber").value;

    let otp = this.f.otp.value;
    let temp = new FirstTime();
    temp.dateOfBirth = this.datePipe.transform(
      new Date(this.f.dateOfBirth.value),
      "yyyy-MM-dd"
    );
    temp.fullName = this.f.fullName.value;
    temp.phoneNumber = this.f.phoneNumber.value;
    this.otpService
      .validateOtp("nnnagi", mobileNumber, "user", otp, temp)
      .subscribe(
        (res) => {
          console.log(res);
          if (res["token"] == "valid" && res["id"]) {
            let otpModel = new OtpModel();
            otpModel.mobileNo = mobileNumber;
            otpModel.otp = otp;
            this.userService.otpModel = otpModel;
            temp.transactionId = res["id"];
            this.userService.firstTimeModel = temp;
            this.router.navigate(["/sbi-collect-app"]);
          } else if (res["token"] == "completed") {
            //TODO COMPLETED
            this.toastr.warning("Application already submiited");
            this.router.navigate(["/"]);
          } else {
            this.toastr.error("Invalid Otp");
          }
        },
        (error) => {
          if (error.status == 401) {
            this.toastr.error("Invalid details submitted.Please try again.");
          } else if (error.status == 409) {
            // mobile number already exists
            this.toastr.error(
              "Mobile number already applied for another application."
            );
          } else {
            this.toastr.error("OTP generation failed! Please try again later.");
          }
        }
      );
  }
  get f() {
    return this.firstFormGroup.controls;
  }
  onChange(event) {
    this.userService.applicationType = event;
  }
  resendOtp() {
    this.inValidOtp = false;
    if (this.firstFormGroup.controls["phoneNumber"].invalid) {
      this.toastr.warning("Please enter a valid mobile number.");
      return;
    }
    let mobileNumber = this.firstFormGroup.get("phoneNumber").value;
    let otpModel = new OtpModel();
    otpModel.mobileNo = mobileNumber;
    otpModel.source = "nnnagi";
    //console.log(otpModel);
    this.otpService.getOtp("nnnagi", mobileNumber, "user", otpModel).subscribe(
      (res) => {
        this.resendOtpCount--;
        this.toastr.success(
          "OTP resent to your mobile number. " +
            this.resendOtpCount +
            " try remaining"
        );
      },
      (error) => {
        if (error.status == 401) {
          this.toastr.error("Invalid details submitted.Please try again.");
        } else if (error.status == 409) {
          // mobile number already exists
          this.toastr.error(
            "Mobile number already applied for another application."
          );
        } else {
          this.toastr.error("OTP generation failed! Please try again later.");
        }
      }
    );
  }
}
