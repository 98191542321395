import { DocumentSection } from './document-section.model';
import { ThirdSection } from './third-section.model';
import { FirstSection } from './first-section.model';
import { SecondSection } from './second-section.model';
export class NNNagiDTO {
  applicationtypeid: number;
  applicationtypename: string;
  transactionId: string;
  status: string;
  createdOn: string;
  // tslint:disable-next-line: variable-name
  razorpay_order_id: string;
  // tslint:disable-next-line: variable-name
  razorpay_payment_id: string;
  // tslint:disable-next-line: variable-name
  razorpay_signature: string;
  firstSection: FirstSection;
  secondSection: SecondSection;
  thirdSection: ThirdSection;
  documentSection: DocumentSection;
}
