import { AdminService } from './../services/admin.service';
import { ToastrService } from 'ngx-toastr';
import { ToastrModule } from 'ngx-toastr';

import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class RdAdminGuard implements CanActivate {
  constructor(private authService:AdminService,private router:Router,private toastr:ToastrService){
    
  }
  canActivate(): boolean {
    if(!this.authService.currentUserValue){
      this.toastr.warning("Unauthorized");
      this.router.navigate(['/admin']);
      return false;
    }else{
      return true;
    }
   /*  if (this.authService.getToken() == undefined || this.authService.getToken() == null) {
      this.toastr.warning("Unauthorized");
      this.router.navigate(['/admin']);
      return false;
    }else{

      return true;
    } */
  }
  
}
