export class OtpModel {
    
    admin: string;
    applicationType: string;
    courseStream: string;
    courseType: string;
    mobileNo: string;
    otp: string;
    regdNoUniversity: string;
    rollNoUniversity: string;
    semester: string;
    source: string;
    token: string;
    validitySeconds:string;
  
}
