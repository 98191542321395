import { ToastrModule, ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class RdUserGuard implements CanActivate {
  constructor(private router: Router, private userService: UserService,private toastr:ToastrService) {
  }

  canActivate(): boolean {
    if (this.userService.otpModel ) {
      return true;
    } else {
      this.toastr.error("Please login")
      
      this.router.navigate(['/']);
      return false;
    }
  }
  
}
