import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from 'app/common/shared/models/user';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { OtpModel } from 'app/common/shared/models/otp-model';
import { map } from 'rxjs/operators';
import { TOUCH_BUFFER_MS } from '@angular/cdk/a11y';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private _http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
    this.currentUser = this.currentUserSubject.asObservable();

    this.getToken = this.getToken.bind(this);
    this.getMobileNumber = this.getMobileNumber.bind(this)
  }
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
    
  }
  public setCurrentUserValue(resp) {
    localStorage.setItem('user', JSON.stringify(resp));
    this.currentUserSubject.next(resp);
  }
  public removeCurrentUserValue(){
    localStorage.removeItem('user');
    this.currentUserSubject.next(null);
  }
  public getMobileNumber() {
    return localStorage.getItem('mobileno');
  }
  public removeMobileNumber(){
    localStorage.removeItem('mobileNo');
  }
  public getToken() {
    /* check if  token exist */
    return localStorage.getItem('token');
  }
  baseUrl = environment.apiBaseUrl


  // getOtp(mobileNumber: string, source: string, role: string) {
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'Accept': '*/*',
  //       'source': source,
  //       'mobileno': mobileNumber,
  //       'role': role

  //     })
  //   };
  //   return this._http.post<any>(this.baseUrl + '/otp/generate', '', httpOptions) as Observable<any>;
  // }
  validateOtp(mobileNumber: string, otp: string, source: string, role: string, otpModel: OtpModel) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'source': source,
        'mobileno': mobileNumber,
        'otp': otp,
        'role': role

      })
    };
    return this._http.post<any>(this.baseUrl + '/otp/validate', otpModel, httpOptions).pipe(map(resp => {
      if (resp) {
        localStorage.setItem('mobileno', mobileNumber)
        localStorage.setItem('user', JSON.stringify(resp));
        this.currentUserSubject.next(resp);
      }
      return resp;
    }));
  }
  removeToken() {
    localStorage.removeItem('mobileno');
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    this.currentUserSubject.next(null);
  }
}
