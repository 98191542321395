import { element } from 'protractor';
import { trigger, transition, useAnimation } from "@angular/animations";
import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router, NavigationEnd } from "@angular/router";
import { OtpServiceService } from "app/common/common-service/otp-service.service";
import { OtpModel } from "app/common/shared/models/otp-model";
import { UserService } from "app/rd-eform/services/user.service";
import { rubberBand, slideInUp } from "ng-animate";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-landing",
  templateUrl: "./landing.component.html",
  styleUrls: ["./landing.component.css"],
  animations: [
    trigger("bounce", [transition("* => *", useAnimation(rubberBand))]),
    trigger("slideInUp", [transition("*=>*", useAnimation(slideInUp))]),
  ],
})
export class LandingComponent implements OnInit {

  slideInUp:any;
  bounce:any;
  test: Date = new Date();
  firstFormGroup: FormGroup;
  otpValidated: boolean = false;
  inValidOtp: boolean = false;
  constructor(
    private _formBuilder: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private userService: UserService,
    private otpService: OtpServiceService,
    public dialog: MatDialog
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.router.navigated = false;
      }
    });
 
  }

  ngOnInit(): void {
    var d = document;
    var x = !d.getElementById("razorpay-embed-btn-js");
    if (x) {
      var s = d.createElement("script");
      s.defer = !0;
      s.id = "razorpay-embed-btn-js";
      s.src = "https://cdn.razorpay.com/static/embed_btn/bundle.js";
      d.body.appendChild(s);
    } else {
      var rzp = window["__rzp__"];
      rzp && rzp.init && rzp.init();
    }

   if(localStorage.getItem("visited")!=="true"){
    
   }
    localStorage.setItem("visited","true")
  }
}
